import React from "react";


export class DropdownOptions {
    constructor(
        value,
        display
    ) {
        this.value = value;
        this.display = display;
    }

    static default_verified_by = [
        new DropdownOptions('---', '---'),
        new DropdownOptions('[Entity Verification] - URL Verified - CHANGE', 'URL Verified - CHANGE'),
        new DropdownOptions('[Entity Verification] - URL Verified - VERIFY (NO CHANGE)', 'URL Verified - VERIFY (NO CHANGE)'),
        new DropdownOptions('[Entity Verification] - URL Verified - OTHER', 'URL Verified - OTHER'),
        new DropdownOptions('[Entity Verification] - Phone Verified', 'Phone Verified'),
        new DropdownOptions('[Entity Verification] - Other', 'Other')
    ]


    static default_invalidated_reasons = [
        new DropdownOptions('---', '---'),
        new DropdownOptions('[Entity Verification] - URL Verified - INVALIDATE', 'URL Verified - INVALIDATE'),
        new DropdownOptions('[Entity Verification] - Phone Verified - INVALIDATE', 'Phone Verified - INVALIDATE'),
        new DropdownOptions('[Entity Verification] - Other', 'Other')
    ]

    static phone_verified_by = [
        new DropdownOptions('---', '---'),
        new DropdownOptions('[Entity Verification] - URL Verified - CHANGE', 'URL Verified - CHANGE'),
        new DropdownOptions('[Entity Verification] - URL Verified - VERIFY (NO CHANGE)', 'URL Verified - VERIFY (NO CHANGE)'),
        new DropdownOptions('[Entity Verification] - URL Verified - OTHER', 'URL Verified - OTHER'),
        new DropdownOptions('[Entity Verification] - Phone Verified – NO ANSWER (NO CHANGE)', 'Phone Verified – No Answer (NO CHANGE)'),
        new DropdownOptions('[Entity Verification] - Phone Verified - VERIFY (NO CHANGE)', 'Phone Verified - VERIFY (NO CHANGE)'),
        new DropdownOptions('[Entity Verification] - Other', 'Other')
    ];

    static phone_invalidated_reasons = [
        new DropdownOptions('---', '---'),
        new DropdownOptions('[Entity Verification] - URL Verified - INVALIDATE', 'URL Verified - INVALIDATE'),
        new DropdownOptions('[Entity Verification] - Phone Verified - INVALIDATE DISCONNECTED', 'Phone Verified - INVALIDATE DISCONNECTED'),
        new DropdownOptions('[Entity Verification] - Phone Verified - INVALIDATE', 'Phone Verified - INVALIDATE'),
        new DropdownOptions('[Entity Verification] - Other', 'Other')
    ]

}