import React, { useState, forwardRef, Component } from 'react';
import {APP_COLORS} from "../../common/Constants";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {useDispatch} from 'react-redux';
// import {denormCompanyUpdate} from "./entityVerificationsSlice";

export const AddressAttributeRenderer = forwardRef((props, ref) => {
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const attributeValue = props.value;
    const attributeVerified = props.data[attributeDenormPrefix+'Verified'];
    const attributeInvalidated = props.data[attributeDenormPrefix+'Invalidated'];
    const verifiedIcon = attributeVerified ? <FontAwesomeIcon color={APP_COLORS['green']} icon={faCheck}/> : '';

    const addressString = `${props.data[attributeDenormPrefix+'DeliveryLine1'] ? props.data[attributeDenormPrefix+'DeliveryLine1'] : ''}
                ${props.data[attributeDenormPrefix+'City'] ? props.data[attributeDenormPrefix+'City']: ''},
                ${props.data[attributeDenormPrefix+'Region'] ? props.data[attributeDenormPrefix+'Region']: ''}
                ${props.data[attributeDenormPrefix+'PostalCode'] ? props.data[attributeDenormPrefix+'PostalCode']: ''}`;

    // const
    return (
        <div>
        {attributeInvalidated? <span><FontAwesomeIcon color={APP_COLORS['red']} icon={faXmark}/><s> {verifiedIcon} {addressString}</s></span>: <span>{verifiedIcon} {addressString}</span>}
        </div>
    );

});