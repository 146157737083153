import React, { useState, forwardRef, Component } from 'react';
import {useDispatch} from 'react-redux';
import {denormCompanyUpdate} from "./entityVerificationsSlice";
import {DropdownOptions} from "./enums/DropdownOptions";

export const AttributeEditor = forwardRef((props, ref) => {

    const gridApi = props.api;
    const uiAttributeName = props.colDef.headerName;
    const attributeType = props.attributeType;
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const entityIdColumn = props.entityIdColumn;

    const verifiedByOptions = props.verifiedByOptions ? props.verifiedByOptions : 'default_verified_by';
    const invalidatedReasonOptions = props.invalidatedReasonOptions ? props.invalidatedReasonOptions : 'default_invalidated_reasons'

    const [rowData, setRowData] = useState( props.data);
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const [attributeColumn, setAttributeColumn] = useState( props.column.colId);
    const [attributeValue, setAttributeValue] = useState(rowData[attributeDenormPrefix]);
    const [attributeId, setAttributeId] = useState(rowData[attributeDenormPrefix+'Id']);

    const [verifiedBy, setVerifiedBy] = useState(setVerifiedByBeginState(rowData[attributeDenormPrefix+'VerifiedBy']));
    const [invalidatedReason, setInvalidatedReason] = useState(setInvalidatedReasonBeginState(rowData[attributeDenormPrefix+'InvalidatedReason']));
    const dispatch = useDispatch();

    function setVerifiedByBeginState(beginValue){

        //alert(`verified begin value = ${beginValue}`);
        const validVerifieds = DropdownOptions[verifiedByOptions].map((option)=>option.value);

        if(beginValue === null){
            return '---';
        }
        else if(validVerifieds.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    function setInvalidatedReasonBeginState(beginValue){

        const validReasons =  DropdownOptions[invalidatedReasonOptions].map((option)=>option.value);

        if(beginValue === null){
            return '---';
        }
        else if(validReasons.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Return') {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); //prevent refresh on submit
        try {
            dispatch(denormCompanyUpdate({
                entityVerificationId: rowData["id"],
                entityId: rowData[entityIdColumn],
                attributeType: attributeType,
                attributeDenormPrefix: attributeDenormPrefix,

                attributeId: rowData[attributeDenormPrefix+'Id'],
                columns: {attributeValue: attributeValue,
                            attributeVerifiedBy: verifiedBy === '---' ? null : verifiedBy,
                            attributeInvalidatedReason: invalidatedReason === '---' ? null: invalidatedReason},
                rowIndex: rowIndex
            }));

            // if (url) url.current.focus();
        }
        catch (err){
            console.log(`Had an exception and err = ${JSON.stringify(err)}`);
        }
    };

    const handleCancel = (event) => {
        gridApi.stopEditing();
    };

    const changeVerified = (event) => {
        setVerifiedBy(event.target.value);
        if(event.target.value === '---' && (invalidatedReason === '---')){
            setSubmitEnabled(false);
        }
        else if( event.target.value !== '---' && invalidatedReason !== '---'){ //Can't have both values set.
            setSubmitEnabled(false);
        }
        else {
            setSubmitEnabled(true);
        }
    };

    const changeInvalidated = (event) => {
        setInvalidatedReason(event.target.value);
        if(event.target.value === '---' && verifiedBy === '---' ){
            setSubmitEnabled(false);
        }
        else if(event.target.value !== '---' && verifiedBy !== '---' ){
            setSubmitEnabled(false);
        }
        else{
            setSubmitEnabled(true);
        }
    };

    const parseVerifiedByOptions = () => {
        const returnValue =  DropdownOptions[verifiedByOptions].map(option => <option  value={option.value}>{option.display}</option>);
        return returnValue;
    }

    const parseInvalidatedReasons = () => {
        const returnValue =  DropdownOptions[invalidatedReasonOptions].map(option => <option value={option.value}>{option.display}</option>);
        return returnValue;
    }


    return (
            // <input onKeyPress={this.onKeyPress} ref={this.textInput} defaultValue={this.props.value}/>

            <form style={{backgroundColor: "white", width:"300px"}} onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                <div style={{marginLeft: "10px"}}>
                    <label>{uiAttributeName}:<br/><input style={{width: "280px"}} type="text" value={attributeValue} onChange={(e) => setAttributeValue(e.target.value)}/></label><br/>
                    <label>Verified By:<br/><select style={{width:"280px"}} value={verifiedBy} onChange={changeVerified}>
                        {parseVerifiedByOptions()}
                    </select></label><br/>
                    <label>Invalidated Reason:<br/><select style={{width:"280px"}} value={invalidatedReason} onChange={changeInvalidated}>
                        {parseInvalidatedReasons()}
                    </select></label><br/>
                        <input type="submit" style={{display: "none"}} />
                    <button disabled={!submitEnabled}>Submit</button>&nbsp;&nbsp;&nbsp;<button onClick={handleCancel}>Cancel</button><br/><br/><br/>

                    <label>Attribute Id (edits not applied): <br/><input style={{width: "280px"}} value={attributeId}/></label>
                </div>
            </form>
    );
});