import React, { useState, forwardRef, Component } from 'react';
import {useDispatch} from 'react-redux';
import {denormCompanyUpdate} from "./entityVerificationsSlice";
import {DropdownOptions} from "./enums/DropdownOptions";

export const AddressAttributeEditor = forwardRef((props, ref) => {

    const gridApi = props.api;
    const uiAttributeName = props.colDef.headerName;
    const attributeType = props.attributeType;
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const entityIdColumn = props.entityIdColumn;

    const verifiedByOptions = props.verifiedByOptions ? props.verifiedByOptions : 'default_verified_by';
    const invalidatedReasonOptions = props.invalidatedReasonOptions ? props.invalidatedReasonOptions : 'default_invalidated_reasons'

    const [rowData, setRowData] = useState( props.data);
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const [attributeId, setAttributeId] = useState(rowData[attributeDenormPrefix+'Id']);
    const [deliveryLine1, setDeliveryLine1] = useState(props.data[attributeDenormPrefix+'DeliveryLine1']);
    const [city, setCity] = useState(props.data[attributeDenormPrefix+'City']);
    const [region, setRegion] = useState(props.data[attributeDenormPrefix+'Region']);
    const [country, setCountry] = useState(props.data[attributeDenormPrefix+'Country']);
    const [postalCode, setPostalCode] = useState(props.data[attributeDenormPrefix+'PostalCode']);

    const [verifiedBy, setVerifiedBy] = useState(setVerifiedByBeginState(rowData[attributeDenormPrefix+'VerifiedBy']));
    const [invalidatedReason, setInvalidatedReason] = useState(setInvalidatedReasonBeginState(rowData[attributeDenormPrefix+'InvalidatedReason']));
    const dispatch = useDispatch();

    function setVerifiedByBeginState(beginValue){

        //alert(`verified begin value = ${beginValue}`);
        const validVerifieds = DropdownOptions[verifiedByOptions].map((option)=>option.value);

        if(beginValue === null){
            return '---';
        }
        else if(validVerifieds.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    function setInvalidatedReasonBeginState(beginValue){

        const validReasons =  DropdownOptions[invalidatedReasonOptions].map((option)=>option.value);

        if(beginValue === null){
            return '---';
        }
        else if(validReasons.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault(); //prevent refresh on submit
        try {
            dispatch(denormCompanyUpdate({
                entityVerificationId: rowData["id"],
                companyId: rowData["companyId"],
                entityId: rowData[entityIdColumn],
                attributeType: attributeType,
                attributeDenormPrefix: attributeDenormPrefix,

                attributeId: rowData[attributeDenormPrefix+'Id'],
                columns: {  deliveryLine1: deliveryLine1,
                            city: city,
                            region: region,
                            country: country,
                            postalCode: postalCode,
                            verifiedBy: verifiedBy === '---' ? null : verifiedBy,
                            invalidatedReason: invalidatedReason === '---' ? null: invalidatedReason},
                rowIndex: rowIndex
            }));

            // if (url) url.current.focus();
        }
        catch (err){
            console.log(`Had an exception and err = ${JSON.stringify(err)}`);
        }
    };

    const handleCancel = (event) => {
        gridApi.stopEditing();
    };

    const changeVerified = (event) => {
        setVerifiedBy(event.target.value);
        if(event.target.value === '---' && (invalidatedReason === '---')){
            setSubmitEnabled(false);
        }
        else if( event.target.value !== '---' && invalidatedReason !== '---'){ //Can't have both values set.
            setSubmitEnabled(false);
        }
        else {
            setSubmitEnabled(true);
        }
    };

    const changeInvalidated = (event) => {
        setInvalidatedReason(event.target.value);
        if(event.target.value === '---' && verifiedBy === '---' ){
            setSubmitEnabled(false);
        }
        else if(event.target.value !== '---' && verifiedBy !== '---' ){
            setSubmitEnabled(false);
        }
        else{
            setSubmitEnabled(true);
        }
    };

    const parseVerifiedByOptions = () => {
        const returnValue =  DropdownOptions[verifiedByOptions].map(option => <option value={option.value}>{option.display}</option>);
        return returnValue;
    }

    const parseInvalidatedReasons = () => {
        const returnValue =  DropdownOptions[invalidatedReasonOptions].map(option => <option value={option.value}>{option.display}</option>);
        return returnValue;
    }



    return (
            // <input onKeyPress={this.onKeyPress} ref={this.textInput} defaultValue={this.props.value}/>
            <form style={{backgroundColor: "white", width:"300px"}} onSubmit={handleSubmit}>
                <div style={{marginLeft: "10px"}}>
                <label>Delivery Line 1:<br/><input style={{width: "280px"}} type="text" value={deliveryLine1} onChange={(e) => setDeliveryLine1(e.target.value)}/></label><br/>
                <label>City:<br/><input style={{width: "280px"}} type="text" value={city} onChange={(e) => setCity(e.target.value)}/></label><br/>
                <label>Region:<br/><input style={{width: "280px"}} type="text" value={region} onChange={(e) => setRegion(e.target.value)}/></label><br/>
                <label>Postal Code:<br/><input style={{width: "280px"}} type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/></label><br/>
                <label>Verified By:<br/><select style={{width:"280px"}} value={verifiedBy} onChange={changeVerified}>
                    {parseVerifiedByOptions()}
                </select></label><br/>
                <label>Invalidated Reason:<br/><select style={{width:"280px"}} value={invalidatedReason} onChange={changeInvalidated}>
                    {parseInvalidatedReasons()}
                </select></label><br/>
                <input type="submit" style={{display: "none"}} />
                <button disabled={!submitEnabled}>Submit</button>&nbsp;&nbsp;&nbsp;<button onClick={handleCancel}>Cancel</button><br/><br/><br/>

                <label>Attribute Id (edits not applied): <br/><input style={{width: "280px"}} value={attributeId}/></label>
                </div>
            </form>
    );
});