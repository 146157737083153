/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSortDown, faSortUp, faSort} from "@fortawesome/free-solid-svg-icons/index";

import {Table as BSTable} from 'react-bootstrap';
import Paginator from './Paginator';
import {APP_COLORS} from "../Constants";
import './Table.scss';
import styled from "styled-components";

const TableColumn = styled.td `
  vertical-align: middle !important;
  padding: 5px 5px !important;
`;

const DivHeaderLine = styled.div `
  border-bottom: 2px solid ${APP_COLORS['gray-400']};C
  position: relative;
  top: 43px;
  z-index: 2;
`;

function TableWithPaging(props) {
    const {
        className,
        headers,
        rows,
        countOfResults,
        resultsPerPage,
        onSelectPage,
        currentPage
    } = props;

    return(
        <div>
            <DivHeaderLine />
            <BSTable className={className} responsive size="sm">
                <thead>
                <tr>
                    {headers.map(h => (
                        <TableHeader
                            key={h.id}
                            id={h.id}
                            name={h.name}
                            property={h.property}
                            sortable={h.sortable}
                            sortOrder={h.sortOrder}
                            handleSort={h.handleSort}
                            formatBy={h.formatBy}
                            formatted={h.formatted}
                            style={h.style}
                        />
                    ))}
                </tr>
                </thead>
                <tbody>
                {rows && rows.map(r => {
                        return (
                            <TableRow
                                key={r.id}
                                headers={headers}
                                row={r}
                            />
                        )
                    }
                )}
                </tbody>
            </BSTable>
            <TableFooter
                countOfResults={countOfResults}
                resultsPerPage={resultsPerPage}
                onSelectPage={onSelectPage}
                currentPage={currentPage}
            />
        </div>
    );
}

function TableHeader(props) {
    const {
        name,
        sortable,
        sortOrder,
        handleSort,
        style
    } = props;

    const getSortAbleColumn = () => {
        if (!sortOrder) {
            return (
                <FontAwesomeIcon
                    style={{marginLeft: '3px'}}
                    color={APP_COLORS['tech-blue']}
                    icon={faSort}
                    onClick={() => handleSort(props)}
                />
            );
        }
        if (sortOrder && sortOrder === 'ASC') {
            return (

                <FontAwesomeIcon
                    style={{marginLeft: '3px'}}
                    color={APP_COLORS['tech-blue']}
                    icon={faSortDown}
                    onClick={() => handleSort(props)}
                />
            );
        }
        if (sortOrder && sortOrder === 'DESC') {
            return (
                <FontAwesomeIcon
                    style={{marginLeft: '3px'}}
                    color={APP_COLORS['tech-blue']}
                    icon={faSortUp}
                    onClick={() => handleSort(props)}
                />
            );
        }

    };
    if (sortable) {
        return(
            <td
                onClick={() => handleSort(props)}
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
            >
                {name}
                {getSortAbleColumn()}
            </td>
        );
    }
    return <td style={{ whiteSpace: 'nowrap', ...style }}>{name}</td>;
}

function TableRow(props) {
    const {
        headers,
        row,
    } = props;

    return (
        <tr
            key={`row-${row.id}`}
        >
            {headers.map(h => {
                    return (
                        <TableColumn key={`${h.id}-${row.id}`}>
                            {h.formatted
                                ? h.formatBy(row, h)
                                : row[h.property]}
                        </TableColumn>
                    );
                }

            )}
        </tr>
    );
}

export function TableFooter(props) {

    return (
        <div className={'tablePagination'}>
            {(props.countOfResults && parseInt(props.countOfResults) > 0) &&
            <TablePaginator
                {...props}
            />
            }
        </div>
    )
};

function TablePaginator(props) {
    const {
        countOfResults,
        resultsPerPage,
        onSelectPage,
        currentPage

    } = props;
    const _resultsPerPage = resultsPerPage > 0 ? resultsPerPage : 20;
    const totalPages = Math.ceil(countOfResults / _resultsPerPage);

    return(
        <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            hideEllipsis
            onChange={(numberOfPage) => onSelectPage(numberOfPage)}
        />
    );

}

TableFooter.propTypes = {
    countOfResults: PropTypes.number,
    resultsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    onSelectPage: PropTypes.func,
};

TablePaginator.propTypes = {
    countOfResults: PropTypes.number,
    resultsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    onSelectPage: PropTypes.func,
};

TablePaginator.defaultProps = {
    countOfResults: 0,
    resultsPerPage: 20,
    currentPage: 1,
    onSelectPage: (e) => e,
};

TableWithPaging.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    headers: PropTypes.array.isRequired,
    property: PropTypes.string,
    sortable: PropTypes.bool,
    sortOrder: PropTypes.string,
    formatted: PropTypes.bool,
    formatBy: PropTypes.func,
    handleSort: PropTypes.func,
    countOfResults: PropTypes.number,
    resultsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    onSelectPage: PropTypes.func,
};

TableHeader.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]),
    name: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
    sortOrder: PropTypes.string,
    formatted: PropTypes.bool,
    formatBy: PropTypes.func,
    noHandler: PropTypes.func,
    handleSort: PropTypes.func.isRequired,
};

TableRow.propTypes = {
    headers: PropTypes.array.isRequired,
    row: PropTypes.object.isRequired,
};

TableWithPaging.defaultProps = {
    className: 'toolBeltTable',
};

export default TableWithPaging;
