import React, { useState, forwardRef, Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../../common/Constants";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
// import {useDispatch} from 'react-redux';
// import {denormCompanyUpdate} from "./entityVerificationsSlice";

export const AttributeRenderer = forwardRef((props, ref) => {
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const attributeValue = props.value;
    const attributeVerified = props.data[attributeDenormPrefix+'Verified'];
    const attributeInvalidated = props.data[attributeDenormPrefix+'Invalidated'];
    const verifiedIcon = attributeVerified ? <FontAwesomeIcon color={APP_COLORS['green']} icon={faCheck}/> : '';

    // const
    return (
        <div>
        {attributeInvalidated? <span><FontAwesomeIcon color={APP_COLORS['red']} icon={faXmark}/><s>{verifiedIcon} {attributeValue}</s></span>: <span>{verifiedIcon} {attributeValue}</span>}
        </div>
    );

});